@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400'), url('https://fonts.googleapis.com/css?family=Lato:100');
body {
  font-family: 'Montserrat', sans-serif;
  background: #6D6027; 
  background: linear-gradient(135deg, rgb(0, 0, 0), rgba(0, 0, 0, 0.81) 40%, rgba(53, 31, 4, 0.62) 100%), url('https://uploads.codesandbox.io/uploads/user/92f88955-0a2b-4308-9c2d-d918ef5f6d8a/Hvda-71462.jpg') #000;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

button, input, select, textarea {
  font-family: 'Montserrat', sans-serif;
}

.what-info {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
}

.navbar-item, .column {
  padding: .5rem 1.5rem;
}