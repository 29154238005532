.Countdown {
  text-align: center;
 
}

.Countdown-col {
  display: inline-block;
  color: #fff;
  text-align: center;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  width: 90px;
}

.Countdown-col-element div {
  font-family: 'Lato', sans-serif;
  font-size: 5em;
  border-bottom: 2px solid #c19504;
  margin-bottom: 5px;
}

.Countdown-col-element span {
  font-size: 1em;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .countdown-wraper {
    padding: 0.6vh 1.5rem !important;
  }
}

@media screen and (max-width: 600px) {
  
  .countdown {
    margin: 0 -50px;
  }

  .Countdown-col-element span {
    font-size: 0.8em;
  }

  .Countdown-col-element {
    margin: 0 5px;
    width: 67px;
  }

  .Countdown-col-element div {
    font-size: 3.8em;
  }
}

@media screen and (max-width: 369px) {

  .Countdown-col-element {
    margin: 0 3px;
    width: 67px;
  }

  .Countdown-col-element div {
    font-size: 3.4em;
  }
}