.sub-message {
  color: #bdbfbf;
}
.saiba-mais a {
  text-decoration: underline;
  color: #bdbfbf;
}
.saiba-mais a:hover { 
  color: #fff;
}

.underline-bold {
  border-bottom: 2px solid #fff;
}

.MainSection__header .title.is-1 {
  line-height: 45px !important;
  font-size: 45px !important;
  text-align: left;
  margin: -1.5rem 0 2rem 0 !important;
}

@media screen and (max-width: 1087px) {

  .MainSection__header {
    font-weight: 200;
    margin-bottom: 0 !important;
  }
    
  .MainSection__subtitle {
     line-height: 30px !important;
     font-size: 18px !important;
     text-align: left;
  }

  .sub-message {
    text-align: left;
  }

  .navbar {    
    padding-bottom: 35px;
  }
  
  .navbar-item {
    padding: 1.5rem 1.5rem;
  }

}