@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400);
@import url(https://fonts.googleapis.com/css?family=Lato:100);
@import url(https://fonts.googleapis.com/css?family=Orbitron&display=swap);
body {
  font-family: 'Montserrat', sans-serif;
  background: #6D6027;
  background: linear-gradient(135deg, black, rgba(0, 0, 0, 0.81) 40%, rgba(53, 31, 4, 0.62) 100%), url("https://uploads.codesandbox.io/uploads/user/92f88955-0a2b-4308-9c2d-d918ef5f6d8a/Hvda-71462.jpg") #000;
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }

button, input, select, textarea {
  font-family: 'Montserrat', sans-serif; }

.what-info {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000; }

.navbar-item, .column {
  padding: .5rem 1.5rem; }

.navbar {
  background: transparent !important;
  /*height: 10.25rem;*/
  transition: .6s;
  z-index: 2 !important; }

.navbar-menu a.navbar-item {
  color: #fff;
  font-size: 13px;
  margin: 0 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  padding: 0.5rem 1rem; }

.navbar-brand a.navbar-item {
  font-family: 'Orbitron', sans-serif;
  font-size: 43px !important;
  color: #fff !important;
  margin-right: 30px;
  margin-top: -8px; }

.navbar-brand a.navbar-item span {
  color: #ffcf20 !important; }

.navbar-link.is-active, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:hover {
  background-color: transparent;
  color: #ffcf20; }

.register {
  width: unset !important;
  padding: 14px 20px !important;
  font-size: 0.8rem;
  font-weight: 800; }

.navbar-burger {
  color: #ffffff;
  height: 4.55rem;
  width: 4.55rem; }

.navbar-burger span {
  height: 3px;
  left: calc(50% - 14px);
  width: 28px; }

.navbar-burger span:nth-child(1) {
  top: calc(50% - 13px); }

.navbar-burger span:nth-child(2) {
  top: calc(50% - 3px);
  left: calc(50% - 20px);
  width: 34px; }

.navbar-burger span:nth-child(3) {
  top: calc(50% + 7px); }

.fixed-nav {
  width: 100%;
  margin: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.9) !important;
  transition: .6s; }

@media screen and (max-width: 1087px) {
  .navbar-menu.is-active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #030a14, #03111c 39%, rgba(5, 37, 52, 0.3) 100%) #0e2746; }
  .navbar-menu a.navbar-item {
    padding: 1.5rem 1.5rem !important;
    text-align: center; }
  .navbar-brand .navbar-item {
    margin: 0 auto !important; }
  .fixed-nav {
    position: absolute;
    min-height: 6rem !important; }
  .navbar {
    background: transparent !important;
    /*min-height: 8.25rem;*/ } }

.sub-message {
  color: #bdbfbf; }

.saiba-mais a {
  text-decoration: underline;
  color: #bdbfbf; }

.saiba-mais a:hover {
  color: #fff; }

.underline-bold {
  border-bottom: 2px solid #fff; }

.MainSection__header .title.is-1 {
  line-height: 45px !important;
  font-size: 45px !important;
  text-align: left;
  margin: -1.5rem 0 2rem 0 !important; }

@media screen and (max-width: 1087px) {
  .MainSection__header {
    font-weight: 200;
    margin-bottom: 0 !important; }
  .MainSection__subtitle {
    line-height: 30px !important;
    font-size: 18px !important;
    text-align: left; }
  .sub-message {
    text-align: left; }
  .navbar {
    padding-bottom: 35px; }
  .navbar-item {
    padding: 1.5rem 1.5rem; } }

.Countdown {
  text-align: center; }

.Countdown-col {
  display: inline-block;
  color: #fff;
  text-align: center; }

.Countdown-col-element {
  display: inline-block;
  margin: 0 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 90px; }

.Countdown-col-element div {
  font-family: 'Lato', sans-serif;
  font-size: 5em;
  border-bottom: 2px solid #c19504;
  margin-bottom: 5px; }

.Countdown-col-element span {
  font-size: 1em;
  text-align: center; }

@media screen and (min-width: 1000px) {
  .countdown-wraper {
    padding: 0.6vh 1.5rem !important; } }

@media screen and (max-width: 600px) {
  .countdown {
    margin: 0 -50px; }
  .Countdown-col-element span {
    font-size: 0.8em; }
  .Countdown-col-element {
    margin: 0 5px;
    width: 67px; }
  .Countdown-col-element div {
    font-size: 3.8em; } }

@media screen and (max-width: 369px) {
  .Countdown-col-element {
    margin: 0 3px;
    width: 67px; }
  .Countdown-col-element div {
    font-size: 3.4em; } }

.MainSection {
  padding: 0; }

.hero {
  padding: 0; }

/*
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 10.25rem);
}
*/
.bg_video {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: -1000; }

.MainSection__header {
  font-weight: 200; }

.call-button {
  box-sizing: border-box;
  width: 250px;
  font-size: 13px;
  text-align: center;
  border: 1px solid #c19504;
  color: #fff;
  border-radius: 25px;
  max-width: 100%;
  letter-spacing: 2px;
  background-color: transparent;
  padding: 14px 10px;
  margin: 20px auto;
  transition: background 0.5s; }

.call-button:hover {
  background: #bd991569; }

.call-button-inner {
  box-sizing: border-box;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 800; }

.MainSection__header .title.is-1 {
  font-weight: 200 !important;
  color: #fff;
  line-height: 70px;
  margin: 0.6vh 0; }

.title.is-4 {
  color: #ffcf20; }

.MainSection__subtitle {
  font-size: 20px;
  color: #fff;
  font-weight: 200;
  line-height: 45px;
  letter-spacing: 1px;
  margin: 0 !important; }

.MainSection__image {
  max-width: 600px;
  margin: 0 auto; }

.countdown_title {
  font-size: 20px;
  color: #fff;
  font-weight: 400; }

.progressBar {
  color: #fff; }

.bonus-restantes {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 50px;
  letter-spacing: 2px;
  font-weight: 200;
  font-size: 16px;
  height: 50px;
  text-align: center;
  font-size: 12px; }

.bonus-restantes-qtd {
  font-weight: 800; }

@media screen and (max-width: 1087px) {
  .progressBar {
    margin: 0 -25px !important; }
  .bonus-restantes {
    font-size: 10px; }
  .hero.is-fullheight-with-navbar .hero-body, .hero.is-halfheight .hero-body {
    -webkit-align-items: unset;
            align-items: unset; } }

.PricingTable {
  padding: 160px 0;
  min-height: calc(100vh - 320px);
  background: #6D6027;
  background: url("https://www.colegiosantoantonio.com.br/wp-content/uploads/2015/09/002-blurred-background-texture-vol2.png");
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }

.PricingTable h1 {
  font-weight: 400 !important;
  text-align: center;
  line-height: 45px !important;
  font-size: 30px !important;
  margin: -1.5rem 0 2rem 0 !important;
  color: #000; }

.PricingTable p {
  font-size: 20px;
  color: #fff;
  font-weight: 200;
  line-height: 45px;
  letter-spacing: 1px;
  margin: -14px auto 30px !important;
  text-align: center; }

.swiper-container {
  padding-bottom: 40px; }

.PricingTable .container-slider {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: unset; }

.PricingTable .panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10; }

.PricingTable .pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media (min-width: 900px) {
  .PricingTable .pricing-table {
    -webkit-flex-direction: row;
            flex-direction: row; } }

.PricingTable .pricing-plan {
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 35px;
  width: 280px !important;
  background: #f0e9dc; }

.PricingTable .pricing-plan:last-child {
  border-bottom: none; }

@media (max-width: 320px) {
  .PricingTable .pricing-plan {
    width: 230px !important; } }

@media (min-width: 900px) {
  .PricingTable .pricing-plan:last-child {
    border-right: none; } }

@media (max-width: 1080px) {
  .PricingTable {
    padding: 50px 0;
    margin: -1.5rem -2rem 0 -1rem !important; }
  .PricingTable p {
    line-height: 30px !important;
    font-size: 18px !important;
    text-align: left; } }

.PricingTable .pricing-img {
  margin: 20px 0 5px 0;
  max-width: 100%; }

.PricingTable .pricing-plan img {
  max-width: 50%; }

.PricingTable .pricing-plan:first-child img, .PricingTable .pricing-plan:nth-child(2) img {
  max-width: 35%;
  padding: 24px; }

.PricingTable .pricing-plan:nth-child(3) img, .PricingTable .pricing-plan:nth-child(7) img, .PricingTable .pricing-plan:nth-child(8) img {
  padding: 24px;
  max-width: 61%; }

.PricingTable .pricing-header {
  color: #000;
  font-weight: 600;
  letter-spacing: 1px; }

.PricingTable .pricing-features {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 20px 0 0 0; }

.PricingTable .pricing-features-item {
  border-top: 1px solid #dcdcdc;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0; }

/*
.PricingTable .pricing-features-item:last-child {
  border-bottom: 1px solid #dcdcdc;
}*/
.PricingTable .pricing-price {
  color: #b38400;
  display: block;
  font-weight: 700;
  margin-bottom: 3px;
  font-size: 18px; }

.PricingTable .pricing-button {
  border-radius: 25px;
  color: #60b3da;
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none;
  text-align: center;
  border: 1px solid #60b3da;
  letter-spacing: 1px;
  background-color: transparent;
  padding: 10px 20px;
  transition: background 0.5s;
  font-size: 0.8rem;
  font-weight: 800; }

.PricingTable .pricing-button:hover,
.PricingTable .pricing-button:focus {
  background-color: #e1f1ff; }

.PricingTable .pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff; }

.PricingTable .pricing-button.is-featured:hover,
.PricingTable .pricing-button.is-featured:active {
  background-color: #269aff; }

.ExperiencesSection {
  background: #f0e9dc;
  padding: 160px 0;
  min-height: calc(100vh - 320px); }

.ExperiencesSection h1 {
  font-weight: 400 !important;
  text-align: center;
  line-height: 45px !important;
  font-size: 30px !important;
  margin: -1.5rem 0 2rem 0 !important;
  color: #000; }

.button.is-rounded {
  margin: 4px 0; }

.vertical-timeline-element-title {
  font-weight: 800; }

.vertical-timeline-element-subtitle {
  color: #e4b300; }

.vertical-timeline-element-content {
  margin: 40px 0; }

.vertical-timeline-element-content p {
  font-size: 0.8rem !important; }

.vertical-timeline::before {
  height: calc(100% - 16px) !important; }

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 35px; }

.react-images__positioner {
  z-index: 3 !important; }


